
import './App.css';
import Introduction from './components/Introduction/Introduction';
import ParticlesComp from './components/ParticlesComp/ParticlesComp';
function App() {
   return (
    <div className='App'>
      <Introduction/>
      <ParticlesComp/>
    </div>
   );
}

export default App;
