import React from 'react';
import './Introduction.css'; // This is where you would include specific styles
import logo from '../../img/logo.png';
import { SocialIcon } from 'react-social-icons'
const Introduction = () => {
    const redirectToDiscord = () => {
        window.location.href = 'https://discord.ryzing-gaming.de';
      };
  return (
    <div className="introduction h-screen flex flex-col items-center justify-center bg-polygon-pattern text-center">
        <img src={logo} alt="Ryzing Gaming Logo" className="max-w-xs md:max-w-sm lg:max-w-lg" draggable="false" />
        <h1 className='text-white text-lg md:text-xl lg:text-4xl mt-4'>Ryzing Gaming e.V</h1>
        <button
        onClick={redirectToDiscord}
        className="mt-4 px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
      >
        <SocialIcon url="https://discord.com"></SocialIcon>
        <span className="ml-2">Join our Discord</span>
      </button>
    </div>
  );
};

export default Introduction;